<template>
  <base-layout
    :title="title"
    :backLink="backLink"
    :reloadable="reloadable"
    :footer="true"
  >
    <template v-slot:search-bar>
      <ion-toolbar size="small">
        <ion-title class="ion-text-capitalize" size="small">
          {{ exam_name }}
        </ion-title>
        <ion-title
          class="ion-text-capitalize"
          size="small"
          slot="end"
          v-if="examSubject"
        >
          {{ examSubject.full_mark }}/{{ examSubject.pass_mark }}(F/P)
        </ion-title>
      </ion-toolbar>
    </template>

    <ion-card v-if="loadedStudent">
      <ion-card-header>
        <ion-card-title>
          {{ loadedStudent.user_detail.name }}
        </ion-card-title>
        <ion-item class="ion-no-padding" lines="none">
          <ion-thumbnail slot="end">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/512px-Circle-icons-profile.svg.png"
            />
          </ion-thumbnail>
          <ion-label>
            <p>
              Class: {{ loadedStudent.student.section_standard.standard.name }},
              Section: {{ loadedStudent.student.section_standard.section.name }}
            </p>
            <p>Roll No.: {{ loadedStudent.student.roll_no }}</p>
            <p>Username: {{ loadedStudent.username }}</p>
          </ion-label>
        </ion-item>
      </ion-card-header>
      <ion-card-content>
        <ion-list lines="none">
          <ion-item class="ion-no-padding">
            <ion-label position="floating">Marks Secured</ion-label>
            <ion-input
              autofocus
              type="number"
              step="0.01"
              v-model="entered_marks"
              placeholder="0.00"
            ></ion-input>
          </ion-item>
        </ion-list>
      </ion-card-content>
    </ion-card>
    <ion-list v-else>
      <ion-item lines="none">
        <ion-label>No Students found!</ion-label>
      </ion-item>
    </ion-list>

    <template v-slot:footer>
      <ion-toolbar v-show="loadedStudent">
        <ion-button
          slot="start"
          fill="solid"
          :disabled="!prevStudent"
          :color="!prevStudent ? 'medium' : ''"
          @click="navigateStudent('prev')"
        >
          Save & Prev
        </ion-button>
        <ion-title align="center">
          <ion-button
            size="small"
            fill="clear"
            @click="setStatsModalState(true)"
          >
            <ion-icon
              slot="icon-only"
              :ios="appsOutline"
              :md="appsOutline"
            ></ion-icon>
          </ion-button>
        </ion-title>
        <ion-button
          slot="end"
          color="success"
          fill="solid"
          @click="navigateStudent('next')"
        >
          {{ nextStudent ? "Save & Next" : "Save & View" }}
        </ion-button>
      </ion-toolbar>
    </template>

    <ion-modal
      :is-open="statsModalState"
      @didDismiss="setStatsModalState(false)"
    >
      <base-modal
        :title="title"
        @closeModal="setStatsModalState(false)"
        :footer="true"
      >
        <template v-slot:search-bar>
          <ion-toolbar size="small">
            <ion-title class="ion-text-capitalize" size="small">
              {{ exam_name }}
            </ion-title>
            <ion-title
              class="ion-text-capitalize"
              size="small"
              slot="end"
              v-if="examSubject"
            >
              {{ examSubject.full_mark }}/{{ examSubject.pass_mark }}(F/P)
            </ion-title>
          </ion-toolbar>
        </template>

        <offline-exam-stats
          @loadStudent="loadStudentFromStatsPage"
          :allStudents="allStudents"
          :exam_name="exam_name"
          :examSubject="examSubject"
          :loadedStudent="loadedStudent"
        ></offline-exam-stats>
      </base-modal>
    </ion-modal>
  </base-layout>
</template>

<script>
import moment from "moment";
import BaseLayout from "../../Base/BaseLayout";

import {
  IonToolbar,
  IonTitle,
  // IonCardSubtitle,
  IonCardTitle,
  IonThumbnail,
  IonModal,
  // IonText,
  IonButton,
  IonIcon,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonItem,
  IonList,
  IonLabel,
  loadingController,
  alertController,
  IonInput,
} from "@ionic/vue";

import { appsOutline } from "ionicons/icons";
// import moment from "moment";

// import Editor from "@tinymce/tinymce-vue";
import base_url from "../../../apis/base_url";

import BaseModal from "../../Base/BaseModal";
import OfflineExamStats from "../../Components/Exam/OfflineExam/OfflineExamStats.vue";

export default {
  props: ["exam_subject_id", "exam_name", "title"],

  components: {
    BaseLayout,
    BaseModal,
    OfflineExamStats,
    IonModal,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonTitle,
    // IonCardSubtitle,
    IonCardTitle,
    IonThumbnail,
    IonItem,
    IonList,
    IonLabel,
    IonToolbar,
    // IonText,
    IonButton,
    IonIcon,
    IonInput,
  },

  data() {
    return {
      moment,
      backLink: "exam.list",
      reloadable: false,
      statsModalState: false,
      appsOutline,
      base_url,

      selected_user_id: null,
      entered_marks: null,

      xhrError: {
        header: null,
        description: null,
      },
    };
  },

  computed: {
    marks_for_update() {
      return this.entered_marks ? this.entered_marks : 0;
    },
    examSubject() {
      return this.$store.getters["examSubject/specificResource"];
    },
    allStudents() {
      return this.$store.getters["studentUsersForExamSubject/allResources"];
    },
    examAnswerStates() {
      return this.$store.getters["attribute/examAnswerState"];
    },
    loadedStudent() {
      if (!this.allStudents || !this.selected_user_id) {
        return null;
      }
      return this.allStudents.find((item) => item.id == this.selected_user_id);
    },
    nextStudent() {
      if (!this.allStudents || !this.selected_user_id) {
        return null;
      }
      let index = this.allStudents.findIndex(
        (item) => item.id == this.selected_user_id
      );
      if (index == this.allStudents.length - 1) {
        return null;
      }
      return this.allStudents[index + 1];
    },
    prevStudent() {
      if (!this.allStudents || !this.selected_user_id) {
        return null;
      }
      let index = this.allStudents.findIndex(
        (item) => item.id == this.selected_user_id
      );
      if (index == 0) {
        return null;
      }
      return this.allStudents[index - 1];
    },
  },

  watch: {
    loadedStudent(newVal) {
      if (newVal) {
        this.entered_marks = newVal.pivot.marks_secured;
      }
    },
  },

  methods: {
    loader(message) {
      const loading = loadingController.create({
        message: message,
        backdropDismiss: false,
      });

      return loading;
    },

    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },

    async getAllStudents() {
      await this.$store.dispatch(
        "studentUsersForExamSubject/allResources",
        this.exam_subject_id
      );
      await this.selectUser(this.allStudents[0]);
    },

    async getExamSubject() {
      await this.$store.dispatch(
        "examSubject/specificResource",
        this.exam_subject_id
      );
    },

    async loadStudentFromStatsPage(id) {
      console.log(id);
      let user = this.allStudents.find((item) => item.id == id);
      await this.selectUser(user);
      this.setStatsModalState(false);
    },

    setStatsModalState(state) {
      this.statsModalState = state;
    },

    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    async updateSubjectMark() {
      const data = {
        exam_subject_score_id: this.loadedStudent.pivot.id,
        marks_secured: this.marks_for_update,
      };
      const spinner = await this.loader("Saving...");
      spinner.present();
      try {
        await this.$store.dispatch("examSubjectScore/updateResource", data);
        await this.$store.dispatch(
          "studentUsersForExamSubject/allResources",
          this.exam_subject_id
        );
      } catch (error) {
        this.setErrorResponse(error);
        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );
        alertDialog.present();
      } finally {
        spinner.dismiss();
      }
    },

    async navigateStudent(direction = "next") {
      // if (this.entered_marks != this.loadedStudent.pivot.marks_secured) {
        this.updateSubjectMark();
      // }
      this.navigate(direction);
    },

    async navigate(direction) {
      if (direction === "next") {
        this.gotoNextStudent();
      }
      if (direction === "prev") {
        this.gotoPrevStudent();
      }
    },

    async gotoNextStudent() {
      let nextStudent = this.nextStudent;
      if (nextStudent) {
        await this.selectUser(nextStudent);
      } else {
        this.setStatsModalState(true);
      }
    },
    async gotoPrevStudent() {
      let prevStudent = this.prevStudent;
      if (prevStudent) {
        await this.selectUser(prevStudent);
      }
    },

    async selectUser(user) {
      if (user) {
        this.selected_user_id = user.id;
      }
    },
  },

  created() {
    this.getExamSubject();
    this.getAllStudents();
  },
};
</script>

<style scoped>
ion-toolbar {
  --min-height: 40px !important;
}

#profile-pic {
  width: 600px;
  height: 600px;
  border-radius: 50%;
}
</style>
